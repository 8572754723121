import React from "react"
import LayoutEng from "../../components/LayoutEng"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SEO from "../../components/SEO"

const Gallery = ({data}) => {
  const origami = data.origamis.nodes
  return (
    <LayoutEng>
      <SEO title="Origami" />
      <main className="page gallery-page">
      <div className="origami-list" id="origami">
            {origami.map(origami =>( 
              <Link to={"../../assets/gallery/" + origami.frontmatter.slug} key={origami.id}>
                <div className="origamiThumb boxedIn">
                  <GatsbyImage
                  image={getImage(origami.frontmatter.thumb.childImageSharp.gatsbyImageData)}
                  alt={origami.frontmatter.title}
                  />
                  <div className="origamiDescript">
                  <h5>{origami.frontmatter.title}</h5>
                  <p className="kurziv">by: {origami.frontmatter.author}</p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
      </main>
    </LayoutEng>
  )
}

export default Gallery

export const query = graphql` 
query Gallery {
    origamis: allMarkdownRemark(
      sort: {fields: frontmatter___order, order: ASC}
      filter: {frontmatter: {type: {eq: "origami_image"}}}
    ) {
      nodes {
        frontmatter {
          slug
          order
          title
          description
          author
          thumb {
            childImageSharp {
              gatsbyImageData(
                placeholder: DOMINANT_COLOR
                transformOptions: {fit: CONTAIN}
                width: 450
              )
            }
          }
        }
        id
      }
    }
  }
`